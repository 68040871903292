import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { PrintService } from '../../print-layout/print.service';
import {LocationParaService} from '../../reports/locationparameter/locationparameter-para.service'
import {employmentstatus} from '../../reports/locationparameter/locationparameter-para.model' 
import { ToastrService } from 'ngx-toastr';
@Component({
    selector: 'app-form-employmentstatusreport',
    templateUrl: './employmentstatusreport.component.html',
    styleUrls: ['./employmentstatusreport.component.scss'] 
  })

  export class employmentstatusreportPrintComponent{
    rowIds: string[];
    rowDetails: Promise<any>[];
    rowId:number;
    employmentstatusreportlist: employmentstatus[]=[];
    locationname:string;
    
    constructor( private route: ActivatedRoute,private employmentstatusService:LocationParaService,
      private toastr: ToastrService,private printService: PrintService, private http: HttpClient ){
        this.rowIds = route.snapshot.params['Id']
        .split(',');
        this.rowId = +this.rowIds[0]; 
  }
  ngOnInit() { 
     this.employmentstatusService.getemploymentstatus(Number(this.rowIds[0]))
     .toPromise()
     .then(res => {
       this.employmentstatusreportlist = res;
      //  console.log(res);
      //  console.log('empl'+this.employmentstatusreportlist.length)
     if(this.employmentstatusreportlist.length>0)
     {
      this.printService.onDataReady(true,'Which clinician is allocated to work at a specific health center?')
     }
       else
       {
        this.toastr.error('No data to display', '');
        this.printService.onDataReady(true,'Which clinician is allocated to work at a specific health center?','pdf','',1)
       }
     })
    //  .catch(err => { console.log(err) });
     
   }
   }
