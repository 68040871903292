import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http'; 
// import { ToastrService } from 'ngx-toastr/toastr/toastr.service';
import { ToastrService } from 'ngx-toastr';
import { PrintService } from '../print-layout/print.service';
import { LocationParaService } from '../reports/locationparameter/locationparameter-para.service';
import { employmentstatus } from '../reports/locationparameter/locationparameter-para.model';



@Component({
  selector: 'app-employeestatuslink',
  templateUrl: './employeestatuslink.component.html',
  styleUrls: ['./employeestatuslink.component.scss']
})
export class EmployeestatuslinkComponent implements OnInit {
  rowIds: string[];
  rowId:number;
  employmentstatusreportlist: employmentstatus[]=[];
  isPrint=false;

  constructor( private route: ActivatedRoute,private employmentstatusService:LocationParaService,
        private toastr: ToastrService,private printService: PrintService, private http: HttpClient  ) {
    this.rowIds = route.snapshot.params['Id']
    .split(',');
    this.rowId = +this.rowIds[0]; 
    console.log(this.rowId);
   }

  ngOnInit() { 
    // debugger; 
    // this.employmentstatusService.getemploymentstatus(Number(this.rowIds[0]))
    //  .toPromise()
    //  .then(res => {
    //    this.employmentstatusreportlist = res;
    //    console.log(res);
    //   //  console.log('empl'+this.employmentstatusreportlist.length)
    //  if(this.employmentstatusreportlist.length>0)
    //  {
    //   this.isPrint=true;
    //   this.printService.onDataReady(true,'Which clinician is allocated to work at a specific health center?')
    //   //this.isPrint=false;
    //   setTimeout(() => {
    //     this.isPrint = false; // Show the div again after printing
    //   }, 100);
    //  }
    //    else
    //    {
    //     this.toastr.error('No data to display', '');
    //     this.printService.onDataReady(true,'Which clinician is allocated to work at a specific health center?','pdf','',1)
    //    }
    //  })
    // //  .catch(err => { console.log(err) });
    this.employmentstatusService.getemploymentstatus(Number(this.rowIds[0]))
    .toPromise()
    .then(res => {
      this.employmentstatusreportlist = res;
      console.log(res);
      // Check if there is data to display
      if (this.employmentstatusreportlist.length > 0) {
        this.isPrint = true; // Enable data display
        // Comment out or remove this print-related line if you don't want the print functionality
        // this.printService.onDataReady(true,'Which clinician is allocated to work at a specific health center?')
        
        setTimeout(() => {
          this.isPrint = false; // Show the div again after setting it to true
        }, 100);
      } else {
        this.toastr.error('No data to display', '');
        // Remove the print functionality related line as well
        // this.printService.onDataReady(true,'Which clinician is allocated to work at a specific health center?','pdf','',1)
      }
    })
    .catch(err => { console.log(err); });
     
   }

}
